import { memo, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  Box,
  FormControl,
  IconButton,
  Typography,
} from '@mui/material';
import { DataGrid } from '@mui/x-data-grid';
import {
  DeleteOutline as DeleteOutlineIcon,
  Edit as EditIcon,
} from '@mui/icons-material';
import InfiniteScroll from 'react-infinite-scroll-component';

import { useUsers } from '../../contexts/users/context';
import { useMessageModal } from '../../contexts/messageModal/context';
import FilterButton from '../../components/Buttons/FilterButton';
import Layout from '../../components/Layouts/Layout';
import TableSearchInput from '../../components/Inputs/TableSearchInput';
import Tooltip from '../../components/Tooltip';
import { buildColumn } from '../../helpers/tables';
import { QUERY_LIMIT, START_PAGE_SIZE } from '../../common/constants';
import { buildRows, optionsFilters } from './constants';
import { QueryGetUsersInterface, RowInterface } from '../../interfaces/user';
import classes from './classes';

const Users = () => {
  const navigate = useNavigate();
  const { fetchUsers, removeUser } = useUsers();
  const { openMessageModal } = useMessageModal();

  const [isFetchingUsers, setIsFetchingUsers] = useState<boolean>(false);
  const [rows, setRows] = useState<RowInterface[]>([]);
  const [hasMoreRows, setHasMoreRows] = useState<boolean>(false);
  const [totalUsers, setTotalUsers] = useState<number>(0);
  const [search, setSearch] = useState<string>('');
  const [queryParam, setQueryParam] = useState<QueryGetUsersInterface>({
    limit: QUERY_LIMIT,
    page: START_PAGE_SIZE,
  });

  const setInitValues = (attributesToUpdate?: Partial<QueryGetUsersInterface>) => {
    setRows([]);
    setQueryParam((prevState) => ({
      ...prevState,
      ...attributesToUpdate,
      page: START_PAGE_SIZE,
    }));
  };

  const onClickRemoveUser = async (cell) => {
    openMessageModal({
      title: '¿Estás seguro/a que querés eliminar este usuario?',
      variant: 'infoError',
      message: 'Recordá que no podés deshacer esta acción, se eliminará todo el contenido',
      primaryButton: {
        text: 'Si, eliminar',
        onClick: async () => {
          await removeUser(cell.id);
          setInitValues();
        },
      },
      secondaryButton: {
        text: 'Volver',
      },
      centerText: true,
    });
  };

  const columns = [
    buildColumn({
      field: 'name',
      headerName: 'Nombre y Apellido',
      minWidth: 200,
      flex: 1,
      cellAlign: true,
      renderCellSlice: 32,
    }),
    buildColumn({
      field: 'role',
      headerName: 'Tipo de usuario',
      width: 180,
    }),
    buildColumn({
      field: 'email',
      headerName: 'Email',
      minWidth: 260,
      flex: 1,
      cellAlign: true,
      renderCellSlice: 32,
    }),
    buildColumn({
      field: 'paymentEmail',
      headerName: 'Email de pago',
      minWidth: 260,
      flex: 1,
      cellAlign: true,
      renderCellSlice: 32,
    }),
    buildColumn({
      field: 'document',
      headerName: 'Tipo y N° de Documento',
      width: 180,
    }),
    buildColumn({
      field: 'phone',
      headerName: 'Teléfono',
      width: 180,
    }),
    buildColumn({
      field: 'isActive',
      headerName: '¿Está activo?',
      width: 180,
    }),
    {
      field: 'options',
      headerName: '',
      width: 100,
      hideSortIcons: true,
      renderCell: (cell) => (
        <Box sx={{ display: 'flex', justifyContent: 'space-evenly' }}>
          <IconButton aria-label="edit" size="small" sx={{ width: '24px' }} onClick={() => navigate('./management', { state: { userId: cell.id } })}>
            <EditIcon color="primary" sx={{ fontSize: 16 }} />
          </IconButton>
          <IconButton
            aria-label="edit"
            size="small"
            sx={{ width: '24px' }}
            onClick={() => onClickRemoveUser(cell)}
          >
            <DeleteOutlineIcon color="primary" sx={{ fontSize: 16 }} />
          </IconButton>
        </Box>
      ),
    }];

  const fetchNextUsers = async () => {
    setQueryParam((prevState) => {
      if (prevState.page === queryParam.page) {
        return { ...prevState, page: prevState.page + 1 };
      }
      return prevState;
    });
  };

  const getUsers = async () => {
    setIsFetchingUsers(true);

    const usersDetails = await fetchUsers(queryParam);

    if (usersDetails) {
      const { users, total } = usersDetails;

      setTotalUsers(total);

      if (users.length) {
        setHasMoreRows(users.length === QUERY_LIMIT);
        const newUsersRows = buildRows(users);
        const existingIds = new Set(rows.map((row) => row.id));

        const uniqueUsers = newUsersRows.filter(
          (newRow) => !existingIds.has(newRow.id),
        );

        if (uniqueUsers.length) {
          setRows(rows.length ? [...rows, ...uniqueUsers] : newUsersRows);
        }
      } else {
        setHasMoreRows(false);
      }
    }

    setIsFetchingUsers(false);
  };

  useEffect(() => {
    getUsers();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [queryParam]);

  useEffect(() => {
    setInitValues({ search: search || undefined });
  }, [search]);

  return (
    <Layout marginContainer={false}>
      <Box sx={classes.contentContainer}>
        <Box sx={classes.topContainer}>
          <Typography variant="h1" sx={classes.pageTitle}>
            Usuarios
          </Typography>
          <Box sx={classes.optionsContainer}>
            <FormControl sx={{ width: '235px' }} variant="outlined">
              <TableSearchInput
                endAdornment={(
                  <Tooltip title="Buscar por nombre, apellido, DNI o email..." />
                )}
                label="Buscar"
                onSearch={(e) => setSearch(e)}
              />
            </FormControl>
            <FilterButton options={optionsFilters} query={queryParam} setQuery={setInitValues} />
          </Box>
        </Box>
        <Box height="100%" width="100%">
          <InfiniteScroll
            dataLength={rows.length}
            hasMore={hasMoreRows}
            loader={<div />}
            next={fetchNextUsers}
            style={{ maxHeight: '100%' }}
          >
            <DataGrid
              autoHeight
              columns={columns}
              disableColumnFilter
              disableColumnMenu
              disableRowSelectionOnClick
              hideFooter
              loading={isFetchingUsers}
              paginationMode="server"
              rowCount={totalUsers}
              rows={rows}
              sx={{ borderWidth: '0px' }}
            />
          </InfiniteScroll>
        </Box>
      </Box>
    </Layout>
  );
};

export default memo(Users);
