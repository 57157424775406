import { memo, useState } from 'react';
import {
  Backdrop,
  Box,
  CircularProgress,
  Typography,
} from '@mui/material';
import { useNavigate } from 'react-router-dom';

import { useCourses } from '../../contexts/courses/context';
import { useMessageModal } from '../../contexts/messageModal/context';

import ConfigurationForm from '../../components/Course/ConfigurationForm';
import CourseForm from '../../components/Course/CourseForm';
import InscriptionSteps from '../../components/InscriptionSteps';
import Layout from '../../components/Layouts/Layout';
import PaymentForm from '../../components/Course/PaymentForm';

import {
  ConfigurationDetails,
  CreateCourseFormInterface,
  CreateCourseInterface,
  CreatePaymentInterface,
  PaymentInformation,
} from '../../interfaces/courses';

import classes from './classes';

const NewCourse = () => {
  const { openMessageModal } = useMessageModal();
  const navigate = useNavigate();

  const { createCourse } = useCourses();
  const [currentStep, setCurrentStep] = useState(1);
  const [openBackdrop, setOpenBackdrop] = useState(false);
  const [courseForm, setCourseForm] = useState<CreateCourseInterface>();

  const goToNextStep = () => setCurrentStep((oldCurrentStep) => oldCurrentStep + 1);
  const goToPreviousStep = () => setCurrentStep((oldCurrentStep) => oldCurrentStep - 1);

  const onSubmit = (course: CreateCourseFormInterface) => {
    setCourseForm((currentCourseForm) => ({
      ...currentCourseForm,
      ...course,
      end_date: course.end_date.format('DD/MM/YYYY'),
      end_inscription_date: course.end_inscription_date.format('DD/MM/YYYY'),
      end_publication_date: course?.end_publication_date?.format('DD/MM/YYYY'),
      start_date: course.start_date.format('DD/MM/YYYY'),
      start_inscription_date: course.start_inscription_date.format('DD/MM/YYYY'),
      start_publication_date: course.start_publication_date.format('DD/MM/YYYY'),
      teachers: course.teachers.map(({
        category, name, email, signature,
      }) => ({
        category, name, email, signature,
      })),
      videos: course.videos.filter((video) => video.link !== ''),
    }));

    goToNextStep();
  };

  const onSubmitConfiguration = (configurationDetails: ConfigurationDetails) => {
    setCourseForm((currentCourseForm) => ({
      ...currentCourseForm,
      ...configurationDetails,
    }));

    goToNextStep();
  };

  const onSubmitPaymentForm = async (paymentForm: CreatePaymentInterface) => {
    setOpenBackdrop(true);

    const formData = new FormData();

    courseForm.images.forEach((file) => {
      formData.append('images', file, file.name);
    });

    courseForm.teachers.filter((c) => c.signature.length !== 0).forEach(({ signature, email }) => {
      formData.append('teacherSignature', signature as unknown as File, `${email}.jfif`);
    });

    if (courseForm.edition !== '') {
      formData.append('edition', courseForm.edition);
    }

    formData.append('banner', courseForm.banner);
    formData.append('description', courseForm?.description);
    formData.append('end_date', courseForm.end_date);
    formData.append('end_inscription_date', courseForm.end_inscription_date);
    if (courseForm.end_publication_date) formData.append('end_publication_date', courseForm.end_publication_date);
    formData.append('lecture_hours', courseForm.lecture_hours);
    formData.append('limit_vacancies', courseForm.limit_vacancies);
    formData.append('modality', courseForm.modality);
    formData.append('moodle', String(courseForm.moodle));
    formData.append('name', courseForm.name);
    formData.append('objectives', courseForm?.objectives);
    formData.append('payment_methods', JSON.stringify(paymentForm.payment_methods));
    formData.append('program', courseForm.program);
    formData.append('specialities', JSON.stringify(courseForm.specialities));
    formData.append('start_date', courseForm.start_date);
    formData.append('start_inscription_date', courseForm.start_inscription_date);
    formData.append('start_publication_date', courseForm.start_publication_date);
    formData.append('tariff', JSON.stringify(paymentForm.tariff));
    formData.append('teachers', JSON.stringify(courseForm.teachers.map((teacher) => ({
      category: teacher.category,
      email: teacher.email,
      name: teacher.name,
    }))));
    formData.append('videos', JSON.stringify(courseForm.videos.map((video) => video.link)));
    formData.append('logos', JSON.stringify(courseForm.logos));
    formData.append('certificateContent', courseForm.certificateContent);

    if (paymentForm.isPreinscription) {
      formData.append('preinscription', JSON.stringify(paymentForm.preinscription));
    } else {
      formData.append('preinscription', JSON.stringify(null));
    }

    const resCreateCourse = await createCourse(formData);

    setOpenBackdrop(false);

    if (resCreateCourse) {
      openMessageModal({
        title: 'Tu curso ha sido publicado con éxito',
        variant: 'success',
        primaryButton: {
          text: 'Aceptar',
          onClick: () => {
            navigate('/courses');
          },
        },
        closeButton: () => navigate('/courses'),
      });
    }
  };

  const steps = [
    { text: '1. Información General', onClick: () => setCurrentStep(1) },
    { text: '2. Configuración', onClick: () => setCurrentStep(2) },
    { text: '3. Aranceles', onClick: () => setCurrentStep(3) },
  ];

  const stepGoBack = (information: PaymentInformation | ConfigurationDetails) => {
    setCourseForm((currentInformation) => ({ ...currentInformation, ...information }));

    goToPreviousStep();
  };

  return (
    <Layout>
      <>
        <Typography
          variant="h1"
          color="primary.main"
          sx={classes.title}
        >
          Nuevo curso
        </Typography>
        <InscriptionSteps currentStep={currentStep} customSteps={steps} />
        {currentStep === 1 && (
          <CourseForm
            course={courseForm}
            onSubmit={onSubmit}
          />
        )}
        {currentStep === 2 && (
          <ConfigurationForm
            course={courseForm}
            onClickBack={(configurationDetails) => stepGoBack(configurationDetails)}
            onSubmit={onSubmitConfiguration}
          />
        )}
        {currentStep === 3 && (
          <PaymentForm
            course={courseForm}
            mode="create"
            onClickBack={(paymentInformation) => stepGoBack(paymentInformation)}
            onSubmit={onSubmitPaymentForm}
          />
        )}
        <Backdrop open={openBackdrop}>
          <Box display="flex" flexDirection="column" alignItems="center">
            <CircularProgress size={80} thickness={5} />
            <Typography
              variant="h1"
              mt={1}
              mb={1}
              color="background.paper"
            >
              Tu curso se está publicando
            </Typography>
            <Typography
              variant="h3"
              color="background.paper"
            >
              Aguarda un momento
            </Typography>
          </Box>
        </Backdrop>
      </>
    </Layout>
  );
};

export default memo(NewCourse);
