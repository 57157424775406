import { rolesDictionary } from '../../helpers/roles';
import UserInterface, { RowInterface } from '../../interfaces/user';

export const buildRows = (
  rows: UserInterface[],
): RowInterface[] => rows?.map(({
  document,
  documentType,
  email,
  first_name,
  id,
  isActive,
  last_name,
  paymentEmail,
  phoneNumber,
  role,
}) => ({
  document: documentType.toUpperCase().concat(' ', document),
  email,
  id,
  isActive: isActive ? 'Sí' : 'No',
  name: first_name.concat(' ', last_name),
  paymentEmail: paymentEmail || '',
  phone: phoneNumber,
  role: rolesDictionary[role],
}));

export const optionsFilters = [
  {
    label: rolesDictionary.admin,
    key: 'role',
    value: 'admin',
  },
  {
    label: rolesDictionary.teacher,
    key: 'role',
    value: 'teacher',
  },
  {
    label: rolesDictionary.student,
    key: 'role',
    value: 'student',
  },
];
