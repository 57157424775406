import { GridColDef } from '@mui/x-data-grid';
import { Box } from '@mui/material';

import { BuildColumnProps } from '../interfaces/props';

export const buildColumn = <T extends unknown>({
  cellAlign,
  color,
  field,
  flex,
  headerName,
  minWidth,
  renderCellSlice,
  width,
}: BuildColumnProps<T>): GridColDef => ({
    field,
    flex: flex || null,
    headerName: headerName || '',
    minWidth: minWidth || null,
    sortable: false,
    renderCell: cellAlign ? (cell) => (
      <Box sx={{
        display: 'flex',
        flexDirection: 'column',
        mr: 5,
        whiteSpace: 'normal',
        wordBreak: 'break-word',
        lineHeight: '1.5',
      }}
      >
        <Box sx={{ color }}>{cell.value.slice(0, renderCellSlice || 16)}</Box>
        <Box sx={{ color }}>{cell.value.slice(renderCellSlice || 16)}</Box>
      </Box>
    ) : (cell) => (
      <Box sx={{
        color,
        mr: 5,
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        whiteSpace: 'normal',
        wordBreak: 'break-word',
        lineHeight: '1.5',
      }}
      >
        {cell.value}
      </Box>
    ),
    renderHeader: () => (
      <Box sx={{
        color: 'primary.main',
        lineHeight: '16px',
        mr: 5,
        whiteSpace: 'pre-wrap',
        '&:focus': {
          outlineWidth: 0,
        },
        '&:focus-within': {
          outlineWidth: 0,
        },
      }}
      >
        {headerName}
      </Box>
    ),
    width: width || null,
  });

export default {};
