const classes = {
  modalContainer: {
    alignItems: 'center',
    bgcolor: 'background.paper',
    borderRadius: '0px',
    boxShadow: '0px 4px 5px -2px rgba(0, 0, 0, 0.2), 0px 7px 10px 1px rgba(0, 0, 0, 0.14), 0px 2px 16px 1px rgba(0, 0, 0, 0.12)',
    display: 'flex',
    flexDirection: 'column',
    gap: 0,
    justifyContent: 'center',
    left: '50%',
    maxWidth: '800px',
    p: 2,
    position: 'absolute' as 'absolute',
    top: '50%',
    transform: 'translate(-50%, -50%)',
    width: '100%',
  },
  icon: {
    color: 'secondary.main',
  },
  iconButton: {
    padding: 0,
  },
  closeModalContainer: {
    display: 'flex',
    justifyContent: 'end',
    mb: 2.5,
    width: '100%',
  },
  bodyContainer: {
    mb: 4,
    px: { xs: 0, sm: 2 },
    width: '100%',
  },
  dataGrid: {
    '& .MuiDataGrid-cell': {
      alignItems: 'center',
      borderBottom: 'none',
      display: 'flex',
    },
    '& .MuiDataGrid-columnHeaders': { borderBottom: 'none' },
    borderWidth: '0px',
    fontSize: '12px',
  },
  editButton: {
    alignItems: 'left',
    color: 'primary.main',
    display: 'flex',
    fontWeight: 600,
    mb: 4.5,
  },
  buttonsContainer: {
    alignItems: 'center',
    display: 'flex',
    flexDirection: { xs: 'column', sm: 'row' },
    justifyContent: { xs: 'center', sm: 'end' },
    px: { xs: 0, sm: 4 },
    width: '100%',
  },
  leftButton: {
    marginBottom: { xs: '13px', sm: 0 },
    marginRight: { xs: 0, sm: '13px' },
    maxWidth: '150px',
  },
  rightButton: {
    maxWidth: '150px',
  },
};

export default classes;
