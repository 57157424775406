import { memo } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  Box,
  Button,
  Chip,
  Divider,
  Grid,
  Tooltip,
  Typography,
} from '@mui/material';
import {
  DateRange as DateRangeIcon,
  LocationOn as LocationOnIcon,
} from '@mui/icons-material';

import {
  qualificationsDict,
} from '../../helpers/qualification';
import {
  BIG_CARD,
  dictModalities,
  FINALIZED,
  getColorChipForQualification,
  getDateMessage,
  getStatusDate,
  getTypeCard,
  SMALL_CARD,
} from './constants';

import { Tags } from '../../interfaces/courses';
import { CourseCardProps } from './types';

import classes from './classes';

const CourseCard = ({
  buttonText = 'VER MÁS',
  canBeDisabled,
  date,
  doAndSendOnClick,
  edition,
  image,
  modality,
  qualification,
  tags = [],
  title,
  to,
}: CourseCardProps) => {
  const navigate = useNavigate();
  const statusDate = getStatusDate(date.start, date.end);
  const dateMessage = getDateMessage(statusDate, date.start);
  const typeCard = getTypeCard(image, edition);

  const handleOnClick = () => {
    const send = doAndSendOnClick();

    navigate(to, { state: send });
  };

  const shouldShowChip = qualification && statusDate === FINALIZED;

  return (
    <Box sx={classes.container(typeCard, canBeDisabled && statusDate === FINALIZED)}>
      {typeCard === BIG_CARD && (
        <Box sx={{ position: 'relative' }}>
          <img
            alt={title}
            src={image}
            style={classes.image}
          />
          {tags.length > 0 && tags
            .filter(({ name }) => name !== Tags.Finished)
            .map((tag, index) => (
              <Chip
                key={tag.name}
                label={tag.name}
                size="small"
                sx={classes.tagChip(index, tag.name)}
              />
            ))}
        </Box>
      )}

      <Tooltip title={title}>
        <Typography variant="h3" color="primary.main" sx={classes.title}>
          {title}
        </Typography>
      </Tooltip>

      {typeCard !== SMALL_CARD && (
        <Typography variant="h5" color="secondary.main" sx={classes.edition}>
          {edition}
        </Typography>
      )}

      {typeCard !== SMALL_CARD && <Divider sx={classes.divider} />}

      <Grid container sx={classes.scheduleContainer}>
        {shouldShowChip ? (
          <Grid item xs={12}>
            <Chip
              label={qualificationsDict[qualification]}
              size="small"
              color={getColorChipForQualification(qualificationsDict[qualification])}
              sx={classes.chip}
            />
          </Grid>
        ) : (
          <>
            <Grid item xs={6} sx={classes.scheduleItemContainer(statusDate)}>
              <DateRangeIcon sx={classes.scheduleIcon} />
              <Typography
                variant="subtitle1"
                sx={classes.scheduleTitle}
              >
                {dateMessage}
              </Typography>
            </Grid>
            <Grid item xs={6} sx={classes.scheduleItemContainer()}>
              <LocationOnIcon sx={classes.scheduleIcon} />
              <Typography
                variant="subtitle1"
                sx={classes.scheduleTitle}
              >
                {dictModalities[modality]}
              </Typography>
            </Grid>
          </>
        )}

      </Grid>

      <Button
        variant={typeCard === SMALL_CARD ? 'outlined' : 'contained'}
        disabled={typeCard !== SMALL_CARD && statusDate === FINALIZED}
        onClick={handleOnClick}
        sx={classes.button}
      >
        {buttonText}
      </Button>
    </Box>
  );
};

export default memo(CourseCard);
