import React from 'react';
import { Controller } from 'react-hook-form';
import { FormControl, FormHelperText, IconButton } from '@mui/material';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import ClearIcon from '@mui/icons-material/Clear';
import 'dayjs/locale/es';

import { ControlledDateFieldProps } from '../../interfaces/inputs';

const classes = {
  clearIcon: {
    position: 'absolute',
    right: 40,
    top: '50%',
    transform: 'translateY(-50%)',
  },
  datePicker: {
    '& .MuiInputBase-root': {
      backgroundColor: 'transparent',
      '&:hover': {
        backgroundColor: 'transparent',
      },
      '& .MuiInputBase-input': {
        paddingLeft: '0px',

      },
    },
    '& .MuiFormLabel-root': {
      left: '-12px',
    },
  },
};

const ControlledDateField = ({
  control,
  disabled,
  fieldLabel,
  fieldName,
  isClearable = false,
  minDate = undefined,
  rules,
}: ControlledDateFieldProps) => (
  <Controller
    control={control}
    name={fieldName}
    rules={rules}
    defaultValue={null}
    render={({ field, fieldState: { error } }) => (
      <FormControl
        sx={{ textAlign: 'start' }}
        fullWidth
        variant="standard"
        error={!!error}
      >
        <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="es">
          <DatePicker
            disabled={disabled}
            format="DD / MM / YYYY"
            label={fieldLabel}
            minDate={minDate}
            onChange={(date) => field.onChange(date || null)}
            slotProps={{ textField: { variant: 'filled', size: 'small' } }}
            sx={classes.datePicker}
            value={field.value || null}
          />
        </LocalizationProvider>
        {isClearable && field.value && (
          <IconButton
            onClick={() => field.onChange(null)}
            size="small"
            sx={classes.clearIcon}
          >
            <ClearIcon fontSize="small" />
          </IconButton>
        )}
        {error && (
          <FormHelperText color="error">
            {error.message}
          </FormHelperText>
        )}
      </FormControl>
    )}
  />
);

export default React.memo(ControlledDateField);
