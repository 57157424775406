import { Dispatch } from 'react';
import { Dayjs } from 'dayjs';

export enum TypeAction {
  FETCH_COURSES = 'FETCH_COURSES',
  GET_COURSE = 'GET_COURSE',
}

export enum Tags {
  FewVacanciesLeft = 'Pocas Vacantes',
  Finished = 'Finalizado',
  InscriptionsOpen = 'Inscripción Abierta',
  VacanciesFull = 'Vacantes Agotadas',
}

export type Tag = {
  _id: string;
  name: Tags;
};

// CoursesContext Interfaces
export type Action = {
  type: TypeAction.FETCH_COURSES;
  courses: CourseInterface[];
} | {
  type: TypeAction.GET_COURSE;
  course: CourseInterface
};

export interface CoursesContextType {
  state: { courses: CourseInterface[], course: CourseInterface };
  dispatch: Dispatch<Action>;
}

export enum PaymentMethod {
  Mercadopago = 'mercadopago',
  PaymentLink = 'paymentLink',
}

export enum Modality {
  Mix = 'mixta',
  Presential = 'presencial',
  Virtual = 'virtual',
}

export enum TariffCategory {
  ResidentAndConcurrentPartner = 'Residentes y concurrentes socios',
  ResidentAndConcurrent = 'Residentes y concurrentes no socios',
  DoctorPartner = 'Médicos socios',
  Doctor = 'NO socios',
  Others = 'Otros',
}

export enum Currency {
  // Usd = 'usd', Por el momento quedan habilitados solo cobros en pesos argentinos
  Ars = 'ars',
}

export enum TeacherCategory {
  Director = 'Director',
  Directora = 'Directora',
  Subdirector = 'Subdirector',
  Subdirectora = 'Subdirectora',
  Coordinador = 'Coordinador',
  Coordinadora = 'Coordinadora',
  CoordinadorAcademico = 'Coordinador Académico',
  CoordinadoraAcademico = 'Coordinadora Académico',
  Docente = 'Docente',
}

export interface PaymentInterface {
  currency: Currency,
  amount: string,
}

export interface TariffInterface extends PaymentInterface {
  category: TariffCategory,
  quotes: number
}

export interface Teacher {
  id: string;
  name: string;
  email: string;
  category: TeacherCategory;
  signature: string[];
}

export interface Video {
  link: string;
}

export default interface CourseInterface {
  _id: string;
  banner: string;
  certificateContent: string;
  description: string;
  edition: string;
  end_date: string;
  end_inscription_date: string;
  end_publication_date?: string;
  id: string;
  images: string[];
  internalCourseId: string;
  isPreinscription?: boolean;
  lecture_hours: string;
  limit_vacancies: number;
  logos: string[];
  modality: Modality;
  moodle: boolean;
  name: string;
  objectives: string;
  payment_methods: PaymentMethod[];
  preinscription?: PaymentInterface;
  program: string;
  slug: string;
  specialities: string[];
  start_date: string;
  start_inscription_date: string;
  start_publication_date: string;
  students?: string[];
  tariff: TariffInterface[];
  teachers: Teacher[];
  videos?: string[];
}

export interface ResGetCourses {
  courses: CourseInterface[];
  page: number;
  total: number;
}

export interface RowCourseInterface extends Pick<CourseInterface, 'id' | 'slug' | 'name' | 'moodle'> {
  modality: string;
  publication_date: string;
  inscription_date: string;
  date: string;
  speciality: string;
  see_course: string;
}

export interface CreateCourseFormInterface {
  banner: File;
  description: string;
  edition: string;
  end_date: Dayjs;
  end_inscription_date: Dayjs;
  end_publication_date?: Dayjs;
  images: File[];
  internalCourseId: string;
  isPreinscription?: boolean;
  lecture_hours: string;
  limit_vacancies: string;
  modality: Modality;
  moodle: boolean;
  name: string;
  objectives: string;
  program: string;
  specialities: string[];
  start_date: Dayjs;
  start_inscription_date: Dayjs;
  start_publication_date: Dayjs;
  teachers: Omit<Teacher, 'id'>[];
  videos?: Video[];
}

export type ConfigurationDetails = {
  certificateContent: string;
  logos: string[];
};

export interface PaymentInformation {
  payment_methods: PaymentMethod[];
  preinscription?: PaymentInterface;
  tariff: TariffInterface[];
}

export interface CreateCourseInterface extends ConfigurationDetails, PaymentInformation, Omit<
CreateCourseFormInterface,
'start_publication_date' |
'start_publication_date' |
'end_publication_date' |
'start_inscription_date' |
'end_inscription_date' |
'start_date' |
'end_date'
> {
  start_publication_date: string;
  end_publication_date?: string;
  start_inscription_date: string;
  end_inscription_date: string;
  start_date: string;
  end_date: string;
}

type StringOrFile = string | File;

export interface EditCourseFormInterface extends Omit<CreateCourseFormInterface, 'images'> {
  images: StringOrFile[];
}
export interface EditCourseInterface extends Omit<CreateCourseInterface, 'images'> {
  images: StringOrFile[];
}

interface CreateCategoriesInterface {
  category: string;
  amount: string;
}

export interface CreatePaymentFormInterface {
  payment_methods: string[];
  quotes: string;
  isPreinscription: boolean;
  preinscriptionAmount: string;
  categories: CreateCategoriesInterface[];
}

export interface CreatePaymentInterface extends Pick<CourseInterface, 'tariff' | 'preinscription' | 'payment_methods'> {
  isPreinscription?: boolean;
}

export interface GenericResponseCourse {
  message: string
}

export enum TypeFilter {
  Active = 'active',
  Finished = 'finished',
  Hidden = 'hidden',
  InProgress = 'inProgress',
}

export interface QueryGetCourses {
  courseDescription?: string;
  courseName?: string;
  courseType?: string;
  exclude?: string;
  free?: boolean;
  internalCourseId?: string;
  limit?: number;
  modality?: string;
  moodle?: boolean;
  page?: number;
  paid?: boolean;
  specialty?: string;
  waitingList?: boolean;
}
