const classes = {
  contentContainer: {
    display: 'flex',
    flexDirection: 'column',
    flexGrow: 1,
    gap: { xs: 3, sm: 6 },
    height: 'auto',
    paddingX: 7,
    paddingY: 5,
  },
  inputOptions: {
    borderRadius: 0,
    height: '34px',
    pl: 1,
    'MuiOutlinedInput-notchedOutline': {
      border: '1px solid #909BD7',
    },
  },
  loaderContainer: {
    display: 'flex',
    height: '65px',
    justifyContent: 'center',
    pt: 1,
  },
  optionsContainer: {
    alignItems: 'center',
    display: 'flex',
    flexDirection: { xs: 'column', md: 'row' },
    gap: 3,
    justifyContent: 'center',
  },
  pageTitle: {
    color: 'primary.main',
    fontSize: '32px',
    fontWeight: 700,
  },
  topContainer: {
    alignItems: 'center',
    display: 'flex',
    flexDirection: { xs: 'column', md: 'row' },
    gap: { xs: 3, md: 0 },
    justifyContent: 'space-between',
  },
};

export default classes;
